.Todo {
    background-color: #fff;
    margin-bottom: .25rem;
    border-radius: 5px;
    border: 1px solid #aaa;
    align-items: center;
}

.Todo .Star {
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
}

.Expired {
    border-color: red;
}